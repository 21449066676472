import { Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useState } from "react";
import strings from "../../../localization/strings";
import { ErrorContextType } from "../../../types";
import GenericDialog from "../../generic/generic-dialog";

/**
 * Error context initialization
 */
export const ErrorContext = React.createContext<ErrorContextType>({
  setError: (message: string) => {}
});

/**
 * Provider for error context
 * 
 * @param children children of the component
 * @returns ErrorProvider component
 */
const ErrorHandler: React.FC = ({ children }) => {
  const [ error, setError ] = useState<string>();

  const contextValue = {
    setError: useCallback((message, error) => handleError(message, error), []),
  };

  const handleError = (message: string, error?: any): void => {
    if (error) {
      console.error(error);
    }
    setError(message);
  }

  /**
   * Renders error dialog
   */
  const renderErrorDialog = () => {
    return (
      <GenericDialog
        open={ error !== undefined }
        error={ false }
        onClose={ () => setError(undefined) }
        onCancel={ () => setError(undefined) }
        onConfirm={ () => setError(undefined) }
        title={ strings.errorTitle }
        positiveButtonText={ strings.generic.ok }
      >
        { error &&
          <Typography>{ error }</Typography>
        }
      </GenericDialog>
    );
  }

  /**
   * Component render
   */
  return (
    <ErrorContext.Provider value={ contextValue }>
      { renderErrorDialog() }
      { children }
    </ErrorContext.Provider>
  );
}

export default ErrorHandler;