import { Configuration, DeviceGroupsApi, DeviceSettingsApi, GeneralSettingsApi } from "../generated/client";
import { AccessToken } from "../types";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized device groups api
   *
   * @param token access token
   * @returns initialized deployment groups api
   */
  public static getDeviceGroupsApi(accessToken: AccessToken) {
    return new DeviceGroupsApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized device settings api
   * 
   * @param accessToken access token
   * @returns initialized devices api
   */
  public static getDeviceSettingsApi(accessToken: AccessToken) {
    return new DeviceSettingsApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized general settings api
   * 
   * @param accessToken access token
   * @returns initialized general settings api
   */
  public static getGeneralSettingsApi(accessToken: AccessToken) {
    return new GeneralSettingsApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets api configuration
   *
   * @param accessToken keycloak access token
   */
  private static getConfiguration(accessToken: AccessToken) {
    return new Configuration({
      basePath: process.env.REACT_APP_API_BASE_PATH,
      accessToken: accessToken.token
    });
  }

}