/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementRange,
    MeasurementRangeFromJSON,
    MeasurementRangeFromJSONTyped,
    MeasurementRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeviceGroup
 */
export interface DeviceGroup {
    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGroup
     */
    groupName?: string;
    /**
     * Device group description
     * @type {string}
     * @memberof DeviceGroup
     */
    description?: string;
    /**
     * 
     * @type {Array<MeasurementRange>}
     * @memberof DeviceGroup
     */
    measurementRanges: Array<MeasurementRange>;
}

export function DeviceGroupFromJSON(json: any): DeviceGroup {
    return DeviceGroupFromJSONTyped(json, false);
}

export function DeviceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'measurementRanges': ((json['measurementRanges'] as Array<any>).map(MeasurementRangeFromJSON)),
    };
}

export function DeviceGroupToJSON(value?: DeviceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'groupName': value.groupName,
        'description': value.description,
        'measurementRanges': ((value.measurementRanges as Array<any>).map(MeasurementRangeToJSON)),
    };
}


