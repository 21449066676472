import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, List, Paper, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import { KeycloakInstance } from "keycloak-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { AccessToken, ErrorContextType } from "../../../types";
import { styles } from "./device-groups-view.styles";
import ListElement from "../../common/list-element";
import SaveIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import theme from "../../../theme/theme";
import GenericDialog from "../../generic/generic-dialog";
import { DeviceGroup, DeviceSettings, MeasurementName, MeasurementRange } from "../../../generated/client";
import DeviceIcon from "../../../resources/svg/device-icon";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Api from "../../../api/api";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LimitComponent from "../../common/limit-component/limit-component";
import SideBar from "../../common/sidebar-component/sidebar-component";
import InfoComponent from "../../common/info-component/info-component";
import { ErrorContext } from "../../common/error-context/error-handler";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  keycloak: KeycloakInstance;
  accessToken: AccessToken;
  handleError: (error: string) => void;
}

/**
 * Interface describing component state
 */
interface State {
  error?: Error;
  addDeviceDialog: boolean;
  deviceGroupDialog: boolean;
  updatingGroup: boolean;
  changesMade: boolean;
  clickedDeviceGroup?: DeviceGroup;
  deleteDeviceGroupDialog: boolean;
  selectedDeviceGroup?: DeviceGroup;
  switchGroupConfirmDialog: boolean;
  devices: DeviceSettings[];
  selectedDevices: DeviceSettings[];
  deviceGroups: DeviceGroup[];
  measurementRanges?: MeasurementRange[];
  usedMeasurementNames: MeasurementName[];
  changedDevices: DeviceSettings[];
}

/**
 * Device groups view component
 */
class DeviceGroupsView extends React.Component<Props, State> {

  static contextType: React.Context<ErrorContextType> = ErrorContext;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      addDeviceDialog: false,
      deviceGroupDialog: false,
      changesMade: false,
      deleteDeviceGroupDialog: false,
      switchGroupConfirmDialog: false,
      devices: [],
      selectedDevices: [],
      deviceGroups: [],
      measurementRanges: [],
      updatingGroup: false,
      usedMeasurementNames: [],
      changedDevices: []
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    try {
      await this.fetchData();
    } catch (error) {
      this.context.setError(strings.error.whenFetchingData, error);
    }
    
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes } = this.props;

    return (
      <>
        <Box 
          p={ 4 }
          className={ classes.content }
        >
          { this.renderDeviceGroupViewContent() }
        </Box>
        { this.leftSideBar() }
        { this.renderDeviceGroupDialog() }
        { this.renderAddDeviceDialog() }
        { this.renderDeleteDeviceGroupDialog() }
        { this.renderSwitchDeviceGroupConfirmDialog() }
      </>
    );
  }

  /**
   * Renders device group view content
   */
  private renderDeviceGroupViewContent = () => {
    const { classes } = this.props;
    const { selectedDeviceGroup } = this.state;

    if(selectedDeviceGroup) {
      return (
        <>
          { this.renderDeviceGroupsInformation() }
          <Box>
            <Grid spacing={ 4 } container>
              <Grid
                item
                lg={ 6 }
                className={ classes.gridItem }
              > 
                { this.renderDevicesContainer() }
              </Grid>
              <Grid
                item
                lg={ 6 }
                className={ classes.gridItem }
              >
                { this.renderLimitValues() }
              </Grid>
            </Grid>
          </Box>
        </>
      );
    }

    return(
      <div
        className={ classes.contentEmpty }
      >
        <Typography variant="h1">
          { strings.deviceGroupsView.noDeviceGroupSelected }
        </Typography>
      </div>
    );
  }

  /**
   * Render left sidebar
   */
  private leftSideBar = () => {
    const { deviceGroups } = this.state;

    return (
      <SideBar
        deviceGroupsList={ deviceGroups }
        addDialogToggle= { () => this.toggleDeviceGroupDialog(false) }
        onDeviceGroupClick={ item => this.onDeviceGroupClick(item, true) }
      />
    );
  }

  /**
   * Renders command sending
   */
  private renderDeviceGroupsInformation = () => {
    const { classes } = this.props;
    const { selectedDeviceGroup, changesMade, deviceGroupDialog } = this.state;
    
    return (
      <>
        <div className={ classes.deviceGroupsInfoHeaderWrapper }>
          <Box display="flex" alignItems="center">
            <Typography variant="h2">
              { strings.deviceGroupsView.deviceGroupsInformation }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              startIcon={ <SaveIcon /> }
              disabled={ !changesMade }
              variant="text"
              color="primary"
              onClick={ this.onSaveGroupClick }
            >
              { strings.generic.save }
            </Button>
            <Button
              startIcon={ <EditOutlinedIcon /> }
              disabled={ deviceGroupDialog }
              variant="text"
              color="primary"
              onClick={ () => this.toggleDeviceGroupDialog(true) }
            >
              { strings.generic.edit }
            </Button>
            <Button
              startIcon={ <CloseIcon /> }
              variant="text"
              color="primary"
              onClick={ this.toggleDeleteDeviceGroupDialog }
            >
              { strings.generic.delete }
            </Button>
          </Box>
        </div>
        <InfoComponent
          device={ false }
          selectedDeviceGroup= { selectedDeviceGroup }
        >
        </InfoComponent>
      </>
    );
  }

  /**
   * Renders devices container
   */
  private renderDevicesContainer = () => {
    const { classes } = this.props;
    const { devices } = this.state;

    return (
      <>
        <div className={ classes.devicesContainerHeaderWrapper }>
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography variant="h2" style={{ marginRight: theme.spacing(1) }}>
              { strings.deviceGroupsView.devices }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
          </Box>
          <IconButton
            color="primary"
            disabled={ (devices.filter(device => !device.deviceGroupId).length < 1) }
            title={ strings.deviceGroupsView.addNewDevice }
            onClick={ this.toggleAddDeviceDialog }
          >
            <AddIcon/>
          </IconButton>
        </div>
        <Paper>
          <Box p={ 2 }>
            <List disablePadding>
              { this.renderDevicesList() }
            </List>
          </Box>
        </Paper>
        
      </>
    );
  }

  /**
   * Renders devices list
   */
  private renderDevicesList = () => {
    const { classes } = this.props;
    const { selectedDeviceGroup, devices } = this.state;

    if (!selectedDeviceGroup) {
      return null;
    }

    const filteredDevices = devices.filter(device => device.deviceGroupId === selectedDeviceGroup.id);

    if (filteredDevices.length === 0) {
      return (
        <div className={ classes.devicesListWrapper }>
          <Typography>
            { strings.deviceGroupsView.noDevicesAssigned }
          </Typography>
        </div>
      );
    }

    return filteredDevices.map(device =>
      <ListElement
        icon={ <DeviceIcon /> }
        name={ device.deviceName }
        status={ device.deviceId ?? "" }
        onSecondaryActionClick={ () => this.onDeleteDeviceClick(device) }
        secondaryActionIcon={ <DeleteOutlineIcon/> }
      />
    );
  }

  /**
   * Renders limit values
   */
  private renderLimitValues = () => {
    const { selectedDeviceGroup, usedMeasurementNames } = this.state;

    if (!selectedDeviceGroup) {
      return null;
    }

    return (
      <LimitComponent
        measurementRanges={ selectedDeviceGroup.measurementRanges }
        usedMeasurementNames={ usedMeasurementNames }
        addLimitValues={ this.onAddLimitValuesClick }
        numberFieldValueChange={ this.onNumberFieldValueChange }
        selectMeasurementNameChange={ this.onSelectMeasurementNameChange }
        deleteMeasurementRange={ this.onDeleteMeasurementRangeClick }
      />
    );
  }

  /**
   * Renders update device group dialog
   */
  private renderDeviceGroupDialog = () => {
    const { deviceGroupDialog } = this.state;

    return (
      <GenericDialog
        open={ deviceGroupDialog }
        error={ false }
        onClose={ () => this.toggleDeviceGroupDialog(true) }
        title={ strings.deviceGroupsView.deviceGroupsInformation }
        positiveButtonText={ strings.genericDialog.confirm }
        cancelButtonText={ strings.genericDialog.cancel }
        onCancel={ () => this.toggleDeviceGroupDialog(true) }
        onConfirm={ this.deviceGroupDialogConfirmSwitch }
      >
        { this.renderDeviceGroupDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders update device group dialog content
   */
  private renderDeviceGroupDialogContent = () => {
    const { selectedDeviceGroup } = this.state;

    return (
      <>
        <Box mb={ 4 }>
          <TextField
            id="deviceGroupName"
            label={ strings.deviceGroupsView.deviceGroupName }
            variant="filled"
            type="text"
            onChange={ this.onNameFieldValueChange }
            placeholder={ selectedDeviceGroup?.groupName }
            fullWidth
          />
        </Box>
        <TextField
          id="deviceGroupDescription"
          label={ strings.deviceGroupsView.deviceGroupDescription }
          variant="filled"
          type="text"
          onChange={ this.onDescriptionFieldValueChange }
          placeholder={ selectedDeviceGroup?.description }
          fullWidth
          multiline
        >
      </TextField>
    </>
    );
  }

  /**
   * Renders add device dialog
   */
  private renderAddDeviceDialog = () => {
    const { addDeviceDialog } = this.state;

    return (
      <GenericDialog
        open={ addDeviceDialog }
        error={ false }
        onClose={ this.toggleAddDeviceDialog }
        title={ strings.deviceGroupsView.addNewDevice }
        positiveButtonText={ strings.generic.add }
        cancelButtonText={ strings.genericDialog.cancel }
        onCancel={ this.toggleAddDeviceDialog }
        onConfirm={ this.onAddDeviceButtonClick }
      >
        { this.renderAddDeviceDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders add device dialog content
   */
  private renderAddDeviceDialogContent = () => {
    const { devices, selectedDevices } = this.state;

    const checkBoxes = devices
    .filter(device => !device.deviceGroupId)
    .map(device => 
      <FormControlLabel
        label={ device.deviceName }
        control={
          <Checkbox
            checked={ selectedDevices.some(selectedDevice => selectedDevice.deviceId === device.deviceId) }
            onChange={ this.onCheckboxChange(device.deviceId ?? "") }
            name={ device.deviceId }
          />
        }
      />
    );

    return (
      <FormGroup>
        { checkBoxes }
      </FormGroup>
    );
  }

  /**
   * Renders remove device dialog
   */
  private renderDeleteDeviceGroupDialog = () => {
    const { deleteDeviceGroupDialog } = this.state;

    return (
      <GenericDialog
        open={ deleteDeviceGroupDialog }
        error={ false }
        onClose={ this.toggleDeleteDeviceGroupDialog }
        title={ strings.deviceGroupsView.deleteCustomerDialog.title }
        positiveButtonText={ strings.genericDialog.confirm }
        cancelButtonText={ strings.genericDialog.cancel }
        onCancel={ this.toggleDeleteDeviceGroupDialog }
        onConfirm={ this.onDeleteDeviceGroupClick }
      >
        { this.renderDeleteDeviceGroupDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders content for remove device group dialog
   */
  private renderDeleteDeviceGroupDialogContent = () => {
    const { selectedDeviceGroup } = this.state;

    if (!selectedDeviceGroup || !selectedDeviceGroup.id) {
      return null;
    }

    return (
      <Typography variant="h4">
        {
          strings.formatString(
            strings.deviceGroupsView.deleteCustomerDialog.explanation,
            selectedDeviceGroup.groupName ?? selectedDeviceGroup.id
          )
        }
      </Typography>
    );
  }

  /**
   * Renders switch device group confirm
   */
  private renderSwitchDeviceGroupConfirmDialog = () => {
    const { switchGroupConfirmDialog, clickedDeviceGroup } = this.state;

    if (!clickedDeviceGroup) {
      return;
    }

    return (
      <GenericDialog
        open={ switchGroupConfirmDialog }
        error={ false }
        onClose={ this.toggleSwitchDeviceGroupConfirmDialog }
        title={ strings.deviceGroupsView.switchGroupDialog.title }
        positiveButtonText={ strings.genericDialog.confirm }
        cancelButtonText={ strings.genericDialog.cancel }
        onCancel={ this.toggleSwitchDeviceGroupConfirmDialog }
        onConfirm={ () => this.onDeviceGroupClick(clickedDeviceGroup) }
      >
        <Typography>
          { strings.deviceGroupsView.switchGroupDialog.explanation }
        </Typography>
      </GenericDialog>
    );
  }

  /**
   * A two-way switch into add device group and update device group methods
   */
  private deviceGroupDialogConfirmSwitch = () => {
    const { updatingGroup } = this.state;

    updatingGroup ?
      this.onUpdateDeviceGroupClick() :
      this.onAddDeviceGroupClick();
  }

  /**
   * Event handler for add device group dialog toggle
   * 
   * @param updatingGroup true if updating group, otherwise adding group
   */
  private toggleDeviceGroupDialog = (updatingGroup: boolean) => {

    this.setState({ 
      updatingGroup: updatingGroup,
      deviceGroupDialog: !this.state.deviceGroupDialog,
      selectedDeviceGroup: updatingGroup ?
        this.state.selectedDeviceGroup :
        { groupName: "", description: "", measurementRanges: [] }
    });
  };

  /**
   * Event handler for add device dialog toggle
   */
  private toggleAddDeviceDialog = () => {
    this.setState({ addDeviceDialog: !this.state.addDeviceDialog });
  };

  /**
   * Event handler for delete device group dialog toggle
   */
  private toggleDeleteDeviceGroupDialog = () => {
    this.setState({ deleteDeviceGroupDialog: !this.state.deleteDeviceGroupDialog });
  }

  /**
   * Event handler for switch device group dialog toggle
   * 
   * @param deviceGroup device group
   */
  private toggleSwitchDeviceGroupConfirmDialog = (deviceGroup?: DeviceGroup) => {

    this.setState({
      switchGroupConfirmDialog: !this.state.switchGroupConfirmDialog,
      clickedDeviceGroup: deviceGroup ?? this.state.clickedDeviceGroup
    });
  }

  /**
   * Event handler for device group click
   *
   * @param deviceGroup clicked device group
   * @param needsConfirmation true if clicked from the devicegroups list, otherwise clicked from dialog
   */
  private onDeviceGroupClick = (deviceGroup: DeviceGroup, needsConfirmation?: boolean) => {
    const { changesMade, changedDevices, devices } = this.state;

    if (needsConfirmation && changesMade) {
      this.toggleSwitchDeviceGroupConfirmDialog(deviceGroup);
      return;
    }
    const groupMeasurementNames = deviceGroup.measurementRanges.map(range => range.measurementName);
    let revertedDevices: DeviceSettings[] = [];

    if (changedDevices.length > 0) {
      revertedDevices = devices.map(device => {
        const result = changedDevices.find(item => item.deviceId === device.deviceId);
        return result !== undefined ? result : device;
      });
    }

    this.setState({
      usedMeasurementNames: groupMeasurementNames,
      selectedDeviceGroup: deviceGroup,
      measurementRanges: [],
      selectedDevices: [],
      clickedDeviceGroup: undefined,
      switchGroupConfirmDialog: false,
      changesMade: false,
      devices: (changedDevices.length > 0) ? revertedDevices : devices,
      changedDevices: []
    });
  }

  /**
   * Event handler for delete device group click
   */
  private onDeleteDeviceGroupClick = async (): Promise<void> => {
    const { accessToken } = this.props;
    const { selectedDeviceGroup } = this.state;

    if (!selectedDeviceGroup?.id) {
      return;
    }

    try {
      const devicesApi = Api.getDeviceSettingsApi(accessToken);
      const deviceGroupsApi = Api.getDeviceGroupsApi(accessToken);

      const deviceSettingsWithGroup = await devicesApi.listDeviceSettings({ groupId: selectedDeviceGroup.id });
      for (const device of deviceSettingsWithGroup) {
        if (device.deviceId && device.deviceGroupId) {
          await devicesApi.updateDeviceSettings({
            deviceId : device.deviceId,
            deviceSettings: { ...device, deviceGroupId: undefined }
          });
        }
      }

      await deviceGroupsApi.deleteDeviceGroup({ deviceGroupId: selectedDeviceGroup.id });
      const updatedDevices = await devicesApi.listDeviceSettings({ });

      this.setState({
        usedMeasurementNames: [],
        deviceGroups: this.state.deviceGroups.filter(group => group.id !== selectedDeviceGroup.id),
        devices: updatedDevices,
        selectedDeviceGroup: undefined,
        deleteDeviceGroupDialog: false
      });
    } catch (error) {
      this.context.setError(strings.error.whenDeletingDeviceGroup, error);
    }
  }

  /**
   * Event handler for delete device button click
   * 
   * @param device Device that is being removed
   */
  private onDeleteDeviceClick = async (device: DeviceSettings) => {

    device.deviceId && this.onDeviceUpdate(
      false,
      {
        ...device,
        deviceGroupId: undefined
      }
    );
  }

  /**
   * Event handler for delete measurement range click
   * 
   * @param measurementRange measurement range to be deleted
   */
  private onDeleteMeasurementRangeClick = (measurementRange: MeasurementRange) => {
    const { selectedDeviceGroup, usedMeasurementNames } = this.state;
    if (!selectedDeviceGroup) {
      return;
    }
    const { measurementRanges } = selectedDeviceGroup;
    
    this.onGroupUpdate({
      ...selectedDeviceGroup,
      measurementRanges: measurementRanges.filter(range =>
        range.measurementName !== measurementRange.measurementName
      )
    });

    this.setState({
      usedMeasurementNames: usedMeasurementNames.filter(name =>
        name !== measurementRange.measurementName
      )
    });
  }

  /**
   * Event handler for checkbox mode change
   *
   * @param name name
   */
  private onCheckboxChange = (name: string) => () => {
    const { devices } = this.state;

    const checkedDevice = devices.find(device => device.deviceId === name);
    if (!checkedDevice) {
      return;
    }

    this.setState({
      selectedDevices: this.state.selectedDevices.some(device => device.deviceId === name) ?
        this.state.selectedDevices.filter(device => device.deviceId !== name) :
        [ ...this.state.selectedDevices, checkedDevice ]
    });
  }

  /**
   * Event handler for measurement name select change
   * 
   * @param measurementRange measurement range object associated with the dropdown
   * @param prevName name of previously used measurement
   */
  private onSelectMeasurementNameChange = async (measurementRange: MeasurementRange, prevName: string) => {
    const { selectedDeviceGroup, usedMeasurementNames } = this.state;

    if (!selectedDeviceGroup) {
      return;
    }

    this.onGroupUpdate({
      ...selectedDeviceGroup,
      measurementRanges: selectedDeviceGroup.measurementRanges.map(item =>
        item.measurementName === prevName ?
          measurementRange :
          item
      )
    });
    
    this.setState({
      usedMeasurementNames: usedMeasurementNames.map(name =>
        name === prevName ? measurementRange.measurementName : name
      )
    });
  }

  /**
   * Event handler for text field value change
   * 
   * @param measurementRange Measurement range in question
   * @param event Event that is passed on change
   */
  private onNumberFieldValueChange = (measurementRange: MeasurementRange) => {
    const { selectedDeviceGroup } = this.state;

    if (!selectedDeviceGroup || !selectedDeviceGroup.measurementRanges) {
      return;
    }

    const updatedList = selectedDeviceGroup.measurementRanges.map(item =>
      item.measurementName === measurementRange.measurementName ?
        measurementRange :
        item
    );

    this.onGroupUpdate({ ...selectedDeviceGroup, measurementRanges: updatedList });
  }

  /**
   * Event handler for name field value change
   * 
   * @param event Event that is passed on change
   */
  private onNameFieldValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { selectedDeviceGroup } = this.state;
    const { value } = event.target;
    
    selectedDeviceGroup && this.onGroupUpdate({ ...selectedDeviceGroup, groupName: value });
  }

  /**
   * Event handler for description field value change
   * 
   * @param event Event that is passed on change
   */
  private onDescriptionFieldValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { selectedDeviceGroup } = this.state;
    const { value } = event.target;
    
    selectedDeviceGroup && this.onGroupUpdate({ ...selectedDeviceGroup, description: value });
  }

  /**
   * Event handler for add limit values click
   */
  private onAddLimitValuesClick = () => {
    const { selectedDeviceGroup, usedMeasurementNames } = this.state;

    if (!selectedDeviceGroup?.id) {
      return;
    }

    const measurementName = Object.values(MeasurementName).find(name => !usedMeasurementNames.includes(name));
    if (!measurementName) {
      return;
    }

    this.onGroupUpdate({
      ...selectedDeviceGroup,
      measurementRanges: [
        ...selectedDeviceGroup.measurementRanges,
        { measurementName, minValue: 0, maxValue: 0 }
      ]
    });

    this.setState({
      usedMeasurementNames: [ ...usedMeasurementNames, measurementName ]
    });
  }

  /**
   * Event handler for add device group click
   */
  private onAddDeviceGroupClick = async () => {
    const { accessToken } = this.props;
    const { deviceGroups, selectedDeviceGroup } = this.state;

    if (!selectedDeviceGroup) {
      return;
    }

    try {
      const deviceGroupsApi = Api.getDeviceGroupsApi(accessToken);
      const createdDeviceGroup = await deviceGroupsApi.createDeviceGroup({
        deviceGroup: selectedDeviceGroup
      });

      this.setState({
        deviceGroups: [ ...deviceGroups, createdDeviceGroup ],
        deviceGroupDialog: false
      });
    } catch (error) {
      this.context.setError(strings.error.whenAddingDeviceGroup, error);
    }
  }

  /**
   * Event handler for add device button click
   */
  private onAddDeviceButtonClick = async () => {
    const { selectedDeviceGroup, selectedDevices } = this.state;

    if (!selectedDeviceGroup || !selectedDevices) {
      return;
    }

    for (const selectedDevice of selectedDevices) {
      this.onDeviceUpdate(
        true,
        {
          ...selectedDevice,
          deviceGroupId: selectedDeviceGroup.id
        }
      );
    }
    this.setState({ addDeviceDialog: false });
  }

  /**
   * Event handler for update device group click
   */
  private onUpdateDeviceGroupClick = () => {
    const { selectedDeviceGroup } = this.state;
    
    if(!selectedDeviceGroup || !selectedDeviceGroup.id) {
      return;
    }

    this.onGroupUpdate({
      id: selectedDeviceGroup.id,
      groupName: selectedDeviceGroup.groupName,
      measurementRanges: selectedDeviceGroup.measurementRanges
    });

    this.setState({ deviceGroupDialog: false });
  }

  /**
   * Event handler for save button click
   */
  private onSaveGroupClick = async () => {
    const { accessToken } = this.props;
    const { selectedDeviceGroup, devices, changedDevices } = this.state;

    if (!selectedDeviceGroup || !selectedDeviceGroup.id) {
      return;
    }

    try {
      const deviceGroupsApi = Api.getDeviceGroupsApi(accessToken);
      const updatedDeviceGroup = await deviceGroupsApi.updateDeviceGroup({
        deviceGroupId: selectedDeviceGroup.id,
        deviceGroup: selectedDeviceGroup
      });

      const devicesApi = Api.getDeviceSettingsApi(accessToken);
      let updatedDevices: DeviceSettings[] = [];
      if (changedDevices.length > 0) {
        devices.forEach(async device => {

          if (device.deviceId && changedDevices.find(item => item.deviceId === device.deviceId)) {
            await devicesApi.updateDeviceSettings({
              deviceId: device.deviceId,
              deviceSettings: device
            });
          }
        });
        updatedDevices = await devicesApi.listDeviceSettings({});
      }

      this.setState({
        deviceGroups: this.state.deviceGroups.map(group =>
          group.id === updatedDeviceGroup.id ? updatedDeviceGroup : group
        ),
        selectedDeviceGroup: updatedDeviceGroup,
        devices: (changedDevices.length > 0) ? updatedDevices : devices,
        changedDevices: [],
        changesMade: false
      });
    } catch (error) {
      this.context.setError(strings.error.whenUpdatingDeviceGroup, error);
    }
  }

  /**
   * Event handler for device group update
   *
   * @param deviceGroup updated device group 
   */
  private onGroupUpdate = (deviceGroup: DeviceGroup) => {
    this.setState({
      deviceGroups: this.state.deviceGroups.map(item =>
        item.id === deviceGroup.id ? deviceGroup : item
      ),
      selectedDeviceGroup: deviceGroup,
      changesMade: true
    });
  }

  /**
   * Event handler for save settings click
   *
   * @param addAction true if adding device
   * @param deviceToSave device to save
   */
  private onDeviceUpdate = (addAction: boolean, deviceToSave?: DeviceSettings) => {
    const { selectedDeviceGroup } = this.state;

    if (!deviceToSave || !deviceToSave.deviceId || !selectedDeviceGroup?.id) {
      return;
    }

    this.setState({
      devices: this.state.devices.map(item =>
        item.deviceId === deviceToSave.deviceId ?
          deviceToSave : 
          item
      ),
      changedDevices: [ ...this.state.changedDevices, addAction ?
        { ...deviceToSave, deviceGroupId: undefined } :
        { ...deviceToSave, deviceGroupId: selectedDeviceGroup?.id } ],
      changesMade: true
    });
  }

  /**
   * Fetch devices data from API
   */
  private fetchData = async () => {
    const { accessToken } = this.props;
    
    try {
      const devicesApi = Api.getDeviceSettingsApi(accessToken);
      const deviceGroupsApi = Api.getDeviceGroupsApi(accessToken);

      const [ devices, deviceGroups ] = await Promise.all([
        devicesApi.listDeviceSettings({}),
        deviceGroupsApi.listDeviceGroups()
      ]);

      this.setState({
        deviceGroups,
        devices
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken as AccessToken,
  keycloak: state.auth.keycloak as KeycloakInstance,
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeviceGroupsView));
