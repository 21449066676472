/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceInfo,
    DeviceInfoFromJSON,
    DeviceInfoFromJSONTyped,
    DeviceInfoToJSON,
    MeasurementRange,
    MeasurementRangeFromJSON,
    MeasurementRangeFromJSONTyped,
    MeasurementRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeviceSettings
 */
export interface DeviceSettings {
    /**
     * 
     * @type {string}
     * @memberof DeviceSettings
     */
    deviceId?: string;
    /**
     * Device description
     * @type {string}
     * @memberof DeviceSettings
     */
    description?: string;
    /**
     * 
     * @type {Array<MeasurementRange>}
     * @memberof DeviceSettings
     */
    measurementRange: Array<MeasurementRange>;
    /**
     * 
     * @type {string}
     * @memberof DeviceSettings
     */
    deviceName: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceSettings
     */
    deviceGroupId?: string;
    /**
     * 
     * @type {DeviceInfo}
     * @memberof DeviceSettings
     */
    deviceInfo?: DeviceInfo;
}

export function DeviceSettingsFromJSON(json: any): DeviceSettings {
    return DeviceSettingsFromJSONTyped(json, false);
}

export function DeviceSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'measurementRange': ((json['measurementRange'] as Array<any>).map(MeasurementRangeFromJSON)),
        'deviceName': json['deviceName'],
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
        'deviceInfo': !exists(json, 'deviceInfo') ? undefined : DeviceInfoFromJSON(json['deviceInfo']),
    };
}

export function DeviceSettingsToJSON(value?: DeviceSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'description': value.description,
        'measurementRange': ((value.measurementRange as Array<any>).map(MeasurementRangeToJSON)),
        'deviceName': value.deviceName,
        'deviceGroupId': value.deviceGroupId,
        'deviceInfo': DeviceInfoToJSON(value.deviceInfo),
    };
}


