/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeviceSettings,
    DeviceSettingsFromJSON,
    DeviceSettingsToJSON,
} from '../models';

export interface CreateDeviceSettingsRequest {
    deviceSettings: DeviceSettings;
}

export interface DeleteDeviceSettingsRequest {
    deviceId: string;
}

export interface FindDeviceSettingsRequest {
    deviceId: string;
}

export interface ListDeviceSettingsRequest {
    groupId?: string;
}

export interface UpdateDeviceSettingsRequest {
    deviceSettings: DeviceSettings;
    deviceId: string;
}

/**
 * 
 */
export class DeviceSettingsApi extends runtime.BaseAPI {

    /**
     * Create new device settings
     */
    async createDeviceSettingsRaw(requestParameters: CreateDeviceSettingsRequest): Promise<runtime.ApiResponse<DeviceSettings>> {
        if (requestParameters.deviceSettings === null || requestParameters.deviceSettings === undefined) {
            throw new runtime.RequiredError('deviceSettings','Required parameter requestParameters.deviceSettings was null or undefined when calling createDeviceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceSettingsToJSON(requestParameters.deviceSettings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceSettingsFromJSON(jsonValue));
    }

    /**
     * Create new device settings
     */
    async createDeviceSettings(requestParameters: CreateDeviceSettingsRequest): Promise<DeviceSettings> {
        const response = await this.createDeviceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete device settings
     */
    async deleteDeviceSettingsRaw(requestParameters: DeleteDeviceSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteDeviceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceSettings/{deviceId}`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete device settings
     */
    async deleteDeviceSettings(requestParameters: DeleteDeviceSettingsRequest): Promise<void> {
        await this.deleteDeviceSettingsRaw(requestParameters);
    }

    /**
     * Find device settings
     */
    async findDeviceSettingsRaw(requestParameters: FindDeviceSettingsRequest): Promise<runtime.ApiResponse<DeviceSettings>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling findDeviceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceSettings/{deviceId}`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceSettingsFromJSON(jsonValue));
    }

    /**
     * Find device settings
     */
    async findDeviceSettings(requestParameters: FindDeviceSettingsRequest): Promise<DeviceSettings> {
        const response = await this.findDeviceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all device settings
     */
    async listDeviceSettingsRaw(requestParameters: ListDeviceSettingsRequest): Promise<runtime.ApiResponse<Array<DeviceSettings>>> {
        const queryParameters: any = {};

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceSettingsFromJSON));
    }

    /**
     * List all device settings
     */
    async listDeviceSettings(requestParameters: ListDeviceSettingsRequest): Promise<Array<DeviceSettings>> {
        const response = await this.listDeviceSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update device settings
     */
    async updateDeviceSettingsRaw(requestParameters: UpdateDeviceSettingsRequest): Promise<runtime.ApiResponse<DeviceSettings>> {
        if (requestParameters.deviceSettings === null || requestParameters.deviceSettings === undefined) {
            throw new runtime.RequiredError('deviceSettings','Required parameter requestParameters.deviceSettings was null or undefined when calling updateDeviceSettings.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateDeviceSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceSettings/{deviceId}`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceSettingsToJSON(requestParameters.deviceSettings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceSettingsFromJSON(jsonValue));
    }

    /**
     * Update device settings
     */
    async updateDeviceSettings(requestParameters: UpdateDeviceSettingsRequest): Promise<DeviceSettings> {
        const response = await this.updateDeviceSettingsRaw(requestParameters);
        return await response.value();
    }

}
