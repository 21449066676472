/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MeasurementName {
    Temperature = 'temperature',
    Light = 'light',
    Airpressure = 'airpressure',
    Humidity = 'humidity',
    Movecount = 'movecount',
    Activitylevel = 'activitylevel',
    Energylevel = 'energylevel',
    Hall = 'hall',
    HallCount = 'hallCount'
}

export function MeasurementNameFromJSON(json: any): MeasurementName {
    return MeasurementNameFromJSONTyped(json, false);
}

export function MeasurementNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementName {
    return json as MeasurementName;
}

export function MeasurementNameToJSON(value?: MeasurementName | null): any {
    return value as any;
}

