/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeviceGroup,
    DeviceGroupFromJSON,
    DeviceGroupToJSON,
} from '../models';

export interface CreateDeviceGroupRequest {
    deviceGroup: DeviceGroup;
}

export interface DeleteDeviceGroupRequest {
    deviceGroupId: string;
}

export interface FindDeviceGroupRequest {
    deviceGroupId: string;
}

export interface UpdateDeviceGroupRequest {
    deviceGroup: DeviceGroup;
    deviceGroupId: string;
}

/**
 * 
 */
export class DeviceGroupsApi extends runtime.BaseAPI {

    /**
     * Create new device group
     */
    async createDeviceGroupRaw(requestParameters: CreateDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroup>> {
        if (requestParameters.deviceGroup === null || requestParameters.deviceGroup === undefined) {
            throw new runtime.RequiredError('deviceGroup','Required parameter requestParameters.deviceGroup was null or undefined when calling createDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceGroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceGroupToJSON(requestParameters.deviceGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupFromJSON(jsonValue));
    }

    /**
     * Create new device group
     */
    async createDeviceGroup(requestParameters: CreateDeviceGroupRequest): Promise<DeviceGroup> {
        const response = await this.createDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete device group
     */
    async deleteDeviceGroupRaw(requestParameters: DeleteDeviceGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceGroupId === null || requestParameters.deviceGroupId === undefined) {
            throw new runtime.RequiredError('deviceGroupId','Required parameter requestParameters.deviceGroupId was null or undefined when calling deleteDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceGroups/{deviceGroupId}`.replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(requestParameters.deviceGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete device group
     */
    async deleteDeviceGroup(requestParameters: DeleteDeviceGroupRequest): Promise<void> {
        await this.deleteDeviceGroupRaw(requestParameters);
    }

    /**
     * Find device group
     */
    async findDeviceGroupRaw(requestParameters: FindDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroup>> {
        if (requestParameters.deviceGroupId === null || requestParameters.deviceGroupId === undefined) {
            throw new runtime.RequiredError('deviceGroupId','Required parameter requestParameters.deviceGroupId was null or undefined when calling findDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceGroups/{deviceGroupId}`.replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(requestParameters.deviceGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupFromJSON(jsonValue));
    }

    /**
     * Find device group
     */
    async findDeviceGroup(requestParameters: FindDeviceGroupRequest): Promise<DeviceGroup> {
        const response = await this.findDeviceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all device groups
     */
    async listDeviceGroupsRaw(): Promise<runtime.ApiResponse<Array<DeviceGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceGroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceGroupFromJSON));
    }

    /**
     * List all device groups
     */
    async listDeviceGroups(): Promise<Array<DeviceGroup>> {
        const response = await this.listDeviceGroupsRaw();
        return await response.value();
    }

    /**
     * Update device group
     */
    async updateDeviceGroupRaw(requestParameters: UpdateDeviceGroupRequest): Promise<runtime.ApiResponse<DeviceGroup>> {
        if (requestParameters.deviceGroup === null || requestParameters.deviceGroup === undefined) {
            throw new runtime.RequiredError('deviceGroup','Required parameter requestParameters.deviceGroup was null or undefined when calling updateDeviceGroup.');
        }

        if (requestParameters.deviceGroupId === null || requestParameters.deviceGroupId === undefined) {
            throw new runtime.RequiredError('deviceGroupId','Required parameter requestParameters.deviceGroupId was null or undefined when calling updateDeviceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deviceGroups/{deviceGroupId}`.replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(requestParameters.deviceGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceGroupToJSON(requestParameters.deviceGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceGroupFromJSON(jsonValue));
    }

    /**
     * Update device group
     */
    async updateDeviceGroup(requestParameters: UpdateDeviceGroupRequest): Promise<DeviceGroup> {
        const response = await this.updateDeviceGroupRaw(requestParameters);
        return await response.value();
    }

}
