import { Box, Button, Paper, Typography, WithStyles, withStyles, TextField } from "@material-ui/core";
import { KeycloakInstance } from "keycloak-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { AccessToken, ErrorContextType } from "../../../types";
import { styles } from "./devices-view.styles";
import SaveIcon from "@material-ui/icons/ArrowDownward";
import { DeviceSettings, MeasurementName, MeasurementRange } from "../../../generated/client";
import Api from "../../../api/api";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GenericDialog from "../../generic/generic-dialog";
import SideBar from "../../common/sidebar-component/sidebar-component";
import InfoComponent from "../../common/info-component/info-component";
import LimitComponent from "../../common/limit-component/limit-component"
import { ErrorContext } from "../../common/error-context/error-handler";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  keycloak?: KeycloakInstance;
  accessToken?: AccessToken;
  groupId?: string;
  handleError: (error: string) => void;
}

/**
 * Interface describing component state
 */
interface State {
  error?: Error;
  deviceSettings: DeviceSettings[];
  selectedDeviceSettings?: DeviceSettings;
  updateDeviceSettingsDialog: boolean;
  changesMade: boolean;
  usedMeasurementNames: MeasurementName[];
}

/**
 * Devices view component
 */
class DevicesView extends React.Component<Props, State> {

  static contextType: React.Context<ErrorContextType> = ErrorContext;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      deviceSettings: [],
      updateDeviceSettingsDialog: false,
      changesMade: false,
      usedMeasurementNames: []
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    await this.fetchData();
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes } = this.props;

    return (
      <>
        <Box 
          p={ 4 }
          className={ classes.content }
        >
          { this.renderDeviceInformation() }
          { this.renderLimitValues() }
        </Box>
        { this.leftSideBar() }
        { this.renderUpdateDeviceSettingsDialog() }
      </>
    );
  }

  /**
   * Render left sidebar
   */
  private leftSideBar = () => {
    const { deviceSettings } = this.state;

    if (!deviceSettings) {
      return null;
    }

    return (
      <SideBar
        deviceSettingsList={ deviceSettings }
        addDialogToggle={ () => {} }
        onDeviceSettingsClick={ item => this.onDeviceSettingsClick(item, true) }
      />
    );
  }

  /**
   * Renders device information
   */
  private renderDeviceInformation = () => {
    const { changesMade, selectedDeviceSettings } = this.state;
    
    return (
      <>
        <Box
          mb={ 4 }
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h2">
              { strings.devicesView.deviceInformation }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
            <Button
              variant="text"
              color="primary"
            >
              { strings.devicesView.measurementQuery }
            </Button>
          </Box>
          <Button
            startIcon={ <SaveIcon /> }
            variant="text"
            color="primary"
            disabled={ !changesMade }
            onClick={ this.onSaveDeviceSettingsClick }
          >
            { strings.generic.save }
          </Button>
          <Button
            startIcon={ <EditOutlinedIcon/> }
            disabled={ false }
            variant="text"
            color="primary"
            onClick={ this.toggleUpdateDeviceSettingsDialog }
          >
            { strings.generic.edit }
          </Button>
        </Box>
        <Paper>
          <InfoComponent
            device={ true }
            selectedDevice={ selectedDeviceSettings }
          >
          </InfoComponent>
        </Paper>
      </>
    );
  }

  /**
   * Event handler for device settings click
   * 
   * TODO: implement list click action
   * @param deviceSettings clicked device settings
   * @param needsConfirmation true if clicked from the devices list, otherwise clicked from dialog
   */
  private onDeviceSettingsClick = async (deviceSettings: DeviceSettings, needsConfirmation: boolean | undefined) => {

    if (!deviceSettings.deviceId) {
      return;
    }

    this.setState({
      selectedDeviceSettings: this.state.deviceSettings.find(item => item.deviceId === deviceSettings.deviceId)
    });
  }

  /**
   * Renders limit values
   */
  private renderLimitValues = () => {
    const { selectedDeviceSettings, usedMeasurementNames } = this.state;

    if (!selectedDeviceSettings) {
      return null;
    }

    return (
      <LimitComponent
        measurementRanges={ selectedDeviceSettings.measurementRange }
        usedMeasurementNames={ usedMeasurementNames }
        addLimitValues={ this.onAddLimitValuesClick }
        numberFieldValueChange={ this.onNumberFieldValueChange }
        selectMeasurementNameChange={ this.onSelectMeasurementNameChange }
        deleteMeasurementRange={ this.onDeleteMeasurementRangeClick }
      />
    );
  }

  /**
   * Event handler for add limit values click
   */
  private onAddLimitValuesClick = async () => {
    const { selectedDeviceSettings, usedMeasurementNames } = this.state;

    if (!selectedDeviceSettings?.deviceId) {
      return;
    }

    const measurementName = Object.values(MeasurementName).find(name => !usedMeasurementNames.includes(name));
    if (!measurementName) {
      return;
    }

    this.onDeviceSettingsUpdate({
      ...selectedDeviceSettings,
      measurementRange: [
        ...selectedDeviceSettings.measurementRange,
        { measurementName, minValue: 0, maxValue: 0 }
      ]
    });
    
    this.setState({
      usedMeasurementNames: [ ...usedMeasurementNames, measurementName ]
    });
  }

  /**
   * Event handler for text field value change
   * 
   * @param measurementRange Measurement range in question
   */
  private onNumberFieldValueChange = async (measurementRange: MeasurementRange) => {
    const { selectedDeviceSettings } = this.state;

    if (!selectedDeviceSettings || !selectedDeviceSettings.measurementRange) {
      return;
    }

    const updatedList = selectedDeviceSettings.measurementRange.map(item =>
      item.measurementName === measurementRange.measurementName ?
        measurementRange :
        item
    );

    this.onDeviceSettingsUpdate({ ...selectedDeviceSettings, measurementRange: updatedList });
  }

  /**
   * Event handler for measurement name select change
   * 
   * @param measurementRange measurement range object associated with the dropdown
   * @param prevName name of previously used measurement
   */
  private onSelectMeasurementNameChange = (measurementRange: MeasurementRange, prevName: string) => {
    const { selectedDeviceSettings, usedMeasurementNames } = this.state;

    if (!selectedDeviceSettings) {
      return;
    }

    this.onDeviceSettingsUpdate({
      ...selectedDeviceSettings,
      measurementRange: selectedDeviceSettings.measurementRange.map(item =>
        item.measurementName === prevName ? measurementRange : item
      )
    });
    
    this.setState({
      usedMeasurementNames: usedMeasurementNames.map(name =>
        name === prevName ? measurementRange.measurementName : name
      )
    });
  }

  /**
   * Event handler for delete measurement range click
   * 
   * @param measurementRange measurement range to be deleted
   */
  private onDeleteMeasurementRangeClick = async (givenMeasurementRange: MeasurementRange) => {
    const { selectedDeviceSettings, usedMeasurementNames } = this.state;

    if (!selectedDeviceSettings) {
      return;
    }

    const { measurementRange } = selectedDeviceSettings;
    
    this.onDeviceSettingsUpdate({
      ...selectedDeviceSettings,
      measurementRange: measurementRange.filter(range =>
        range.measurementName !== givenMeasurementRange.measurementName
      )
    });

    this.setState({
      usedMeasurementNames: usedMeasurementNames.filter(name =>
        name !== givenMeasurementRange.measurementName
      )
    });
  }

  /**
   * Renders device settings editing dialog
   */
  private renderUpdateDeviceSettingsDialog = () => {
    const { updateDeviceSettingsDialog } = this.state;

    return (
      <GenericDialog
        open={ updateDeviceSettingsDialog }
        error={ false }
        onClose={ this.toggleUpdateDeviceSettingsDialog }
        title={ strings.devicesView.editDeviceInfo }
        positiveButtonText={ strings.genericDialog.confirm }
        cancelButtonText={ strings.genericDialog.cancel }
        onCancel={ this.toggleUpdateDeviceSettingsDialog }
        onConfirm={ this.onUpdateDeviceSettingsClick }
      >
        { this.renderUpdateDeviceSettingsDialogTextFields() }
      </GenericDialog>
    );
  }

  /**
   * Renders text fields for update device settings dialog
   */
  private renderUpdateDeviceSettingsDialogTextFields = () => {
    const { selectedDeviceSettings } = this.state;

    return (
      <>
        <Box>
          <TextField
            id="deviceName"
            label={ strings.devicesView.deviceName }
            variant="filled"
            type="text"
            value={ selectedDeviceSettings?.deviceName }
            onChange={ this.onUpdatedDeviceSettingsFieldChange }
            fullWidth= { true }
          />
        </Box>
        <Box>
          <TextField
            id="description"
            label={ strings.devicesView.deviceDescription }
            variant="filled"
            type="text"
            value={ selectedDeviceSettings?.description }
            onChange={ this.onUpdatedDeviceSettingsFieldChange }
            fullWidth= { true }
          />
        </Box>
      </>
    );
  }

  /**
   * Event handler for update device settings dialog toggle
   * 
   * @param event Event that is passed on change
   */
  private toggleUpdateDeviceSettingsDialog = () => {
    this.setState({ 
      updateDeviceSettingsDialog: !this.state.updateDeviceSettingsDialog,
    });
  };

  /**
   * Event handler for device name change
   */
  private onUpdatedDeviceSettingsFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { selectedDeviceSettings } = this.state;
    const { value, id } = event.target;

    if(!selectedDeviceSettings) {
      return;
    }

    this.setState({
      selectedDeviceSettings: { ...selectedDeviceSettings, [id]: value }
    });
  }


  /**
   * Event handler for device settings update click
   */
  private onUpdateDeviceSettingsClick = () => {
    const { selectedDeviceSettings } = this.state;
    
    if(!selectedDeviceSettings || !selectedDeviceSettings.deviceId) {
      return;
    }

    this.onDeviceSettingsUpdate(selectedDeviceSettings);
    this.setState({ updateDeviceSettingsDialog: false });
  }

  /**
   * Event handler for device settings update
   * 
   * @param updatedDeviceSettings updated device settings
   */
  private onDeviceSettingsUpdate = (updatedDeviceSettings: DeviceSettings) => {
    this.setState({
      deviceSettings: this.state.deviceSettings.map(device => 
        device.deviceId === updatedDeviceSettings.deviceId ?
          updatedDeviceSettings :
          device 
      ),
      selectedDeviceSettings: updatedDeviceSettings,
      changesMade: true
    });
  }

  /**
   * Event handler for save button click
   */
  private onSaveDeviceSettingsClick = async () => {
    const { accessToken } = this.props;
    const { selectedDeviceSettings } = this.state;

    if (!selectedDeviceSettings || !selectedDeviceSettings.deviceId || !accessToken) {
      return;
    }

    try {
      const deviceSettingsApi = Api.getDeviceSettingsApi(accessToken);
      const updatedDeviceSettings = await deviceSettingsApi.updateDeviceSettings({
        deviceId: selectedDeviceSettings.deviceId,
        deviceSettings: selectedDeviceSettings
      });

      this.setState({
        deviceSettings: this.state.deviceSettings.map(settings =>
          settings.deviceId === updatedDeviceSettings.deviceId ? updatedDeviceSettings : settings
        ),
        selectedDeviceSettings: updatedDeviceSettings,
        changesMade: false
      });
    } catch (error) {
      this.context.setError(strings.error.whenUpdatingDeviceSettings, error);
    }
  }

  /**
   * Fetch devices data from API
   */
  private fetchData = async () => {
    const { accessToken } = this.props;

    if(!accessToken) {
      return;
    }
    try {
      const deviceSettingsApi = Api.getDeviceSettingsApi(accessToken);
      const deviceSettings = await deviceSettingsApi.listDeviceSettings({ });

      this.setState({ deviceSettings });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DevicesView));
