import { AppBar, Box, Button, Dialog, IconButton, Link, Tab, Tabs, TextField, Toolbar, Typography, WithStyles, withStyles } from "@material-ui/core";
import { History } from "history";
import { KeycloakInstance } from "keycloak-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { AccessToken, ErrorContextType } from "../../../types";
import DevicesView from "../../views/devices";
import DeviceGroupsView from "../../views/device-groups";
import { styles } from "./home-screen.styles";
import { ReactComponent as Logo } from "../../../resources/svg/Metatavu-M.svg";
import { ErrorContext } from "../../common/error-context/error-handler";
import { GeneralSettings } from "../../../generated/client";
import Api from "../../../api/api";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SettingsIcon from "../../../resources/svg/haltian-settings-icon";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History;
  keycloak?: KeycloakInstance;
  accessToken?: AccessToken;
}

/**
 * Interface describing component state
 */
interface State {
  tabIndex: number;
  generalSettings?: GeneralSettings;
  generalSettingsOpen: boolean;
}

/**
 * Home screen component
 */
class HomeScreen extends React.Component<Props, State> {
  static contextType: React.Context<ErrorContextType> = ErrorContext;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      tabIndex: 0,
      generalSettingsOpen: false
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    try {
      await this.fetchData();
    } catch (error) {
      this.context.setError(strings.error.whenFetchingData, error);
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, keycloak } = this.props;
    const { tabIndex, generalSettingsOpen } = this.state;

    const profile = keycloak?.profile;

    return (
      <>
      <Box height="100vh">
        <AppBar>
          <Toolbar>
            <Box className={ classes.titleArea }>
              <IconButton edge="start" color="inherit" aria-label="logo">
                <Logo className={ classes.logo } />
              </IconButton>
              <Tabs 
                onChange={ this.setTabIndex }
                value={ tabIndex }
              >
                <Tab
                  value={ 0 }
                  label={ strings.header.devices }
                />
                <Tab
                  value={ 1 }
                  label={ strings.header.deviceGroups }
                />
              </Tabs>
            </Box>
            <Box className={ classes.buttonGroup }>
              { profile &&
                <Typography>
                  { profile.username }
                </Typography>
              }
              <Button 
                className={ classes.signOutButton }
                size="small"
                variant="text"
                color="secondary"
                onClick={ () => keycloak?.logout() }
              >
                { strings.header.logOut }
              </Button>
              <IconButton onClick={ () => this.setState({ generalSettingsOpen: true }) }>
                <SettingsIcon/>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar/>
        <Box overflow="auto">
          { tabIndex === 0 && <DevicesView handleError={ this.handleError } /> }
          { tabIndex === 1 && <DeviceGroupsView handleError={ this.handleError } /> }
        </Box>
        { this.renderFooter() }
      </Box>
      <Dialog
        fullScreen
        open={ generalSettingsOpen }
      >
        { this.renderGeneralSettings() }
      </Dialog>
    </>
    );
  }

  /**
   * Renders footer
   */
    private renderFooter = () => {
      const { classes } = this.props;

      return (
        <Box
          p={ 4 }
          display="flex"
          justifyContent="flex-end"
        >
          <Link href="#" target="_blank" className={ classes.link }>
            <Typography color="primary">{ strings.footer.info }</Typography>
          </Link>
          <Box ml={ 2 } mr={ 2 }>
            <Typography>{ "//" }</Typography>
          </Box>
          <Link href="#" target="_blank" className={ classes.link }>
            <Typography color="primary">{ strings.footer.support }</Typography>
          </Link>
          <Box ml={ 2 } mr={ 2 }>
            <Typography>{ "//" }</Typography>
          </Box>
          <Typography>{ strings.footer.copyright }</Typography>
          <Box ml={ 2 }>
            <Typography color="primary">{ strings.footer.year }</Typography>
          </Box>
        </Box>
      );
  }

  /**
   * Render general settings
   */
  private renderGeneralSettings = () => {
    const { classes } = this.props;
    const { generalSettings } = this.state;

    if (!generalSettings) {
      return <Typography>{ strings.messages.somethingWentWrong }</Typography>;
    }

    return (
      <>
        <AppBar
          color="inherit"
          className={ classes.appBar }
        >
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={ 1 }
            >
              <Typography variant="h2">
                { strings.generalSettings.title }
              </Typography>
              <div>
                  <Button
                    startIcon={ <ArrowDownwardIcon/> }
                    variant="text"
                    color="primary"
                    onClick={ this.onSaveGeneralSettingsClick }
                  >
                    { strings.generic.save }
                  </Button>
                  <Button
                    startIcon={ <CloseIcon /> }
                    variant="text"
                    color="primary"
                    onClick={ () => this.setState({ generalSettingsOpen: false }) }
                  >
                    { strings.generic.cancel }
                  </Button>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        { this.renderGeneralSettingsContent() }
      </>
    );
  }

  /**
   * Renders general settings content
   */
  private renderGeneralSettingsContent = () => {
    const { generalSettings } = this.state;

    if (!generalSettings) {
      return <Typography>{ strings.messages.somethingWentWrong }</Typography>;
    }

    return (
      <Box p={ 5 } display="flex" flexDirection="column">
        <Box mb={ 2 } display="flex" flex={ 1 }>
          <TextField
            label={ strings.generalSettings.values.saveInterval }
            fullWidth
            type="number"
            variant="filled"
            name="saveInterval"
            onChange={ this.onIntervalChange }
            value={ generalSettings.saveInterval }
          />
        </Box>
      </Box>
    );
  }

  /**
   * Event handler for measurement interval value change
   *
   * @param event react change event
   */
  private onIntervalChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { generalSettings } = this.state;
    const name = event.target.name;
    const value = Number(event.target.value);

    if (generalSettings && name) {
      this.setState({ generalSettings: { ...generalSettings, [name]: value } });
    }
  }

  /**
   * Event handler for general settings save click
   */
  private onSaveGeneralSettingsClick = async () => {
    const { accessToken } = this.props;
    const { generalSettings } = this.state;

    if (!generalSettings || !generalSettings.id || !accessToken) {
      return;
    }
    
    try {
      const generalSettingsApi = Api.getGeneralSettingsApi(accessToken);
      const updateGeneralSetting = await generalSettingsApi.updateGeneralSettings({
        generalSettingId: generalSettings.id,
        generalSettings
      });

      this.setState({
        generalSettings: updateGeneralSetting,
        generalSettingsOpen: false
      });

    } catch (error) {
      this.context.setError(strings.error.whenUpdatingSettings, error);
    }
    
  }

  /**
   * Sets tab index
   *
   * @param event event object
   * @param newValue new tab index value
   */
  private setTabIndex = (event: React.ChangeEvent<{ }>, newValue: number) => {
    this.setState({ tabIndex: newValue });
  }

  /**
   * Handles error from child components
   * 
   * @param error error message
   */
  private handleError = (error: string) => {
    this.context && this.context.setError(error);
  }
  
  /**
   * Fetch app data
   */
  private fetchData = async () => {
    const { accessToken } = this.props;

    if (!accessToken) {
      return;
    }

    const generalSettingsApi = Api.getGeneralSettingsApi(accessToken);

    try {
      const generalSettingsList = await generalSettingsApi.listGeneralSettings();
      const generalSettings = generalSettingsList ? generalSettingsList[0] : undefined;

      this.setState({ generalSettings });

    } catch (error) {
      return Promise.reject(error);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeScreen));
