/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementName,
    MeasurementNameFromJSON,
    MeasurementNameFromJSONTyped,
    MeasurementNameToJSON,
} from './';

/**
 * Measurement range object
 * @export
 * @interface MeasurementRange
 */
export interface MeasurementRange {
    /**
     * 
     * @type {MeasurementName}
     * @memberof MeasurementRange
     */
    measurementName: MeasurementName;
    /**
     * 
     * @type {number}
     * @memberof MeasurementRange
     */
    maxValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MeasurementRange
     */
    minValue?: number | null;
}

export function MeasurementRangeFromJSON(json: any): MeasurementRange {
    return MeasurementRangeFromJSONTyped(json, false);
}

export function MeasurementRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurementName': MeasurementNameFromJSON(json['measurementName']),
        'maxValue': !exists(json, 'maxValue') ? undefined : json['maxValue'],
        'minValue': !exists(json, 'minValue') ? undefined : json['minValue'],
    };
}

export function MeasurementRangeToJSON(value?: MeasurementRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurementName': MeasurementNameToJSON(value.measurementName),
        'maxValue': value.maxValue,
        'minValue': value.minValue,
    };
}


