import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import en from "./en.json";
import fi from "./fi.json";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {
  comingSoon: string;

  errorTitle: string;
  
  /**
   * Translations related to general settings
   */
  generalSettings: {
    title: string;
    add: string;
    values: {
      saveInterval: string;
      settingsUpdateInterval: string;
      cacheUpdateInterval: string;
      cacheSize: string;
      relayMode: string;
      debugMode: string;
    };
  };
  
  /**
   * Generic translations
   */
  generic: {
    add: string;
    edit: String;
    save: string;
    delete: string;
    cancel: string;
    ok: string;
  };

  /**
   * Translations for header component
   */
  header: {
    logOut: string;
    devices: string;
    deviceGroups: string;
  };
  
  /**
   * Translations related to generic-dialog component
   */
  genericDialog: {
    cancel: string;
    confirm: string;
  }

  /**
   * Translations for devices view
   */
  devicesView: {
    deviceInformation: string;
    measurementQuery: string;
    deviceDescription: string;
    battery: string;
    noSelection: string;
    id: string;
    deviceSettingsInformation: string;
    deviceName: string;
    editDeviceInfo: string;
  };

  /**
   * Translations for device groups view
   */
  deviceGroupsView: {
    addNewDevice: string;
    addNewDeviceGroup: string;
    addNewLimitValue: string;
    deviceGroupsInformation: string;
    deviceGroupDescription: string;
    deviceGroupName: string;
    maxValue: string;
    measurementQuery: string;
    measurementValue: string;
    minValue: string;
    noDevicesAssigned: string;
    noLimitsSet: string;
    devices: string;
    limitValues: string;
    noDeviceGroupSelected: string;
    deleteCustomerDialog: {
      title: string;
      explanation: string;
    };
    switchGroupDialog: {
      title: string;
      explanation: string;
    };
  };

  /**
   * Translations related to generic-dialog component
   */
  devices: {
    devices: string;
    addDevices: string;
    add: string;
    battery: string;
    cancel: string;
    deviceInfo: string;
    id: string;
    sendCommand: string;
    location: string;
    messageQueries: string;
    noDeviceSelected: string;
    updated: string;
  };
  
  footer: {
    copyright: string;
    info: string;
    support: string;
    year: string;
  };

  /**
   * UI messages
   */
  messages: {
    somethingWentWrong: string;
  };

  /**
   * Translations for measurement names
   */
  measurementName: {
    temperature: string;
    light: string;
    airpressure: string;
    humidity: string;
    movecount: string;
    activitylevel: string;
    energylevel: string;
    hall: string;
    hallCount: string;
  };

  /**
   * Translations for displaying errors
   */
  error: {
    whenAddingDevice: string;
    whenAddingDeviceGroup: string;
    whenDeletingDevice: string;
    whenDeletingDeviceGroup: string;
    whenFetchingData: string;
    whenUpdatingSettings: string;
    whenUpdatingDevice: string;
    whenUpdatingDeviceGroup: string;
    whenUpdatingDeviceSettings: string;
  };

}

const strings: IStrings = new LocalizedStrings({ fi, en });

export default strings;
