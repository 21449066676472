import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({
  root: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.background.default
  },

  titleArea: {
    display: "flex",
    alignItems: "center"
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  
  signOutButton: {
    fontWeight: "bold",
    root: {
      textSizeSmall: true
    }
  },
  
  logo: {
    width: "30px",
    height: "30px"
  },

  content: {
    marginLeft: 320
  },

  appBar: {
    position: "relative"
  },
  
  link: {
    textDecoration: "none"
  }
});