import { Box, Drawer, IconButton, List, Toolbar, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./sidebar-component.styles";
import strings from "../../../localization/strings";
import AddIcon from '@material-ui/icons/Add';
import { DeviceGroup, DeviceSettings } from "../../../generated/client";
import ListElement from "../list-element/list-element"
import DeviceGroupsIcon from "../../../resources/svg/device-group-icon";
import DeviceIcon from "../../../resources/svg/device-icon";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  deviceGroupsList?: DeviceGroup[];
  deviceSettingsList?: DeviceSettings[];
  addDialogToggle: () => void;
  onDeviceSettingsClick?: (itemClicked: DeviceSettings) => void;
  onDeviceGroupClick?: (itemClicked: DeviceGroup) => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Side bar component
 */
class SideBar extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, addDialogToggle } = this.props;

    return (
      <Drawer>
        <Toolbar />
        <Box 
          overflow="auto"
          pb={ 4 }
        >
          <List>
            { this.renderList() }
          </List>
        </Box>
        <div className={ classes.iconButtonWrapper }>
          <IconButton
            color="primary"
            title={ strings.deviceGroupsView.addNewDeviceGroup }
            onClick={ addDialogToggle }
          >
            <AddIcon />
          </IconButton>
        </div>
      </Drawer>
    );
  };

  /**
   * Renders list of clickable items
   */
  private renderList = () => {
    const {
      deviceGroupsList,
      deviceSettingsList,
      onDeviceSettingsClick,
      onDeviceGroupClick
    } = this.props;

    if (deviceSettingsList?.length) {
      return deviceSettingsList.map(deviceSetting => 
        <ListElement
          icon={ <DeviceIcon selected={ false } /> }
          name={ deviceSetting.deviceName }
          status={ deviceSetting.deviceInfo?.battery ? deviceSetting.deviceInfo.battery.toString() : deviceSetting.deviceId }
          onClick={ () => onDeviceSettingsClick && onDeviceSettingsClick(deviceSetting) }
          key={ deviceSetting.deviceId }
        />
      );
    }

    if (deviceGroupsList?.length) {
      return deviceGroupsList.map(deviceGroup => 
        <ListElement
          icon={ <DeviceGroupsIcon selected={ false } /> }
          name={ deviceGroup.groupName ?? "" }
          selected={ false }
          onClick={ () => onDeviceGroupClick && onDeviceGroupClick(deviceGroup) }
          key={ deviceGroup.id }
        />
      );
    }

    return null;
  }
}

export default (withStyles(styles)(SideBar));
