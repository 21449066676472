import { SvgIcon, SvgIconProps } from "@material-ui/core";
import * as React from "react";
import theme from "../../theme/theme";

/**
 * Interface representing icon properties
 */
interface Props extends SvgIconProps {
  selected?: boolean;
}

/**
 * Render device icon
 *
 * @param props icon properties
 */
const DeviceIcon = (props: Props) => {
  const { selected } = props; 
  return (
    <SvgIcon { ...props }>
      <circle fill="none" stroke={ selected ? theme.palette.secondary.main : "#696969" } cx="12" cy="12" r="10.6"/>
      <circle fill={ selected ? theme.palette.secondary.main : "#696969" } cx="15.7" cy="14.8" r="2.8"/>
      <circle fill={ selected ? theme.palette.secondary.main : "#696969" } cx="8.3" cy="12.9" r="2.8"/>
      <ellipse fill={ selected ? theme.palette.secondary.main : "#696969" } cx="12.9" cy="7.4" rx="2.8" ry="2.8"/>
    </SvgIcon>
  );
}
export default DeviceIcon;
