import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  contentContainer: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  limitValuesListWrapper: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    width: "100%"
  },

  limitRow: {
    width: "100%"
  },

  limitRowItem: {
    marginBottom: theme.spacing(4),
    "&:last-child": {
      marginBottom: 0
    },
    display: "flex",
    alignItems: "center",
    width: "100%"
  },

  limitRowItemButtonWrapper: {
    display: "flex",
    width: "10%",
    minWidth: "50px"
  },

  limitRowItemSelectWrapper: {
    display: "flex",
    width: "40%",
    marginRight: theme.spacing(1)
  },

  limitRowItemValueWrapper: {
    display: "flex",
    width: "25%",
    marginRight: theme.spacing(1)
  }

});