/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Device info object
 * @export
 * @interface DeviceInfo
 */
export interface DeviceInfo {
    /**
     * Battery status
     * @type {number}
     * @memberof DeviceInfo
     */
    battery?: number;
    /**
     * When was device info data last updated
     * @type {string}
     * @memberof DeviceInfo
     */
    lastUpdate?: string;
}

export function DeviceInfoFromJSON(json: any): DeviceInfo {
    return DeviceInfoFromJSONTyped(json, false);
}

export function DeviceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'battery': !exists(json, 'battery') ? undefined : json['battery'],
        'lastUpdate': !exists(json, 'lastUpdate') ? undefined : json['lastUpdate'],
    };
}

export function DeviceInfoToJSON(value?: DeviceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'battery': value.battery,
        'lastUpdate': value.lastUpdate,
    };
}


