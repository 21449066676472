import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./list-element.styles";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  icon?: JSX.Element;
  name: string;
  status?: string;
  selected?: boolean;
  onClick?: () => void;
  onSecondaryActionClick?: () => void;
  secondaryActionIcon? : JSX.Element;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * List item element
 */
class ListElement extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const { selected, onClick } = this.props;

    if (onClick) {
      return (
        <ListItem
          selected={ selected }
          onClick={ onClick }
          button
        >
          { this.renderListElementContent() }
        </ListItem>
      );
    }

    return (
      <ListItem>
        { this.renderListElementContent() }
      </ListItem>
    );
  }

  /**
   * Renders list element content
   */
  private renderListElementContent = () => {
    const {
      icon,
      name,
      status,
      onSecondaryActionClick,
      secondaryActionIcon
    } = this.props;

    return (
      <>
        { icon &&
          <ListItemIcon>
            { icon }
          </ListItemIcon>
        }
        <ListItemText
          primary={ name }
          secondary={ status }
        />
        { (onSecondaryActionClick && secondaryActionIcon) &&
          <ListItemSecondaryAction>
            <IconButton 
              edge="end"
              onClick={ onSecondaryActionClick }
            >
              { secondaryActionIcon }
            </IconButton>
          </ListItemSecondaryAction>
        }
      </>
    );
  }
}

export default (withStyles(styles)(ListElement));
