/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeneralSettings,
    GeneralSettingsFromJSON,
    GeneralSettingsToJSON,
} from '../models';

export interface CreateGeneralSettingsRequest {
    generalSettings: GeneralSettings;
}

export interface DeleteGeneralSettingsRequest {
    generalSettingId: string;
}

export interface FindGeneralSettingsRequest {
    generalSettingId: string;
}

export interface UpdateGeneralSettingsRequest {
    generalSettings: GeneralSettings;
    generalSettingId: string;
}

/**
 * 
 */
export class GeneralSettingsApi extends runtime.BaseAPI {

    /**
     * Create new general settings
     */
    async createGeneralSettingsRaw(requestParameters: CreateGeneralSettingsRequest): Promise<runtime.ApiResponse<GeneralSettings>> {
        if (requestParameters.generalSettings === null || requestParameters.generalSettings === undefined) {
            throw new runtime.RequiredError('generalSettings','Required parameter requestParameters.generalSettings was null or undefined when calling createGeneralSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generalSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneralSettingsToJSON(requestParameters.generalSettings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralSettingsFromJSON(jsonValue));
    }

    /**
     * Create new general settings
     */
    async createGeneralSettings(requestParameters: CreateGeneralSettingsRequest): Promise<GeneralSettings> {
        const response = await this.createGeneralSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete general settings
     */
    async deleteGeneralSettingsRaw(requestParameters: DeleteGeneralSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.generalSettingId === null || requestParameters.generalSettingId === undefined) {
            throw new runtime.RequiredError('generalSettingId','Required parameter requestParameters.generalSettingId was null or undefined when calling deleteGeneralSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generalSettings/{generalSettingId}`.replace(`{${"generalSettingId"}}`, encodeURIComponent(String(requestParameters.generalSettingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete general settings
     */
    async deleteGeneralSettings(requestParameters: DeleteGeneralSettingsRequest): Promise<void> {
        await this.deleteGeneralSettingsRaw(requestParameters);
    }

    /**
     * Find general settings
     */
    async findGeneralSettingsRaw(requestParameters: FindGeneralSettingsRequest): Promise<runtime.ApiResponse<GeneralSettings>> {
        if (requestParameters.generalSettingId === null || requestParameters.generalSettingId === undefined) {
            throw new runtime.RequiredError('generalSettingId','Required parameter requestParameters.generalSettingId was null or undefined when calling findGeneralSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generalSettings/{generalSettingId}`.replace(`{${"generalSettingId"}}`, encodeURIComponent(String(requestParameters.generalSettingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralSettingsFromJSON(jsonValue));
    }

    /**
     * Find general settings
     */
    async findGeneralSettings(requestParameters: FindGeneralSettingsRequest): Promise<GeneralSettings> {
        const response = await this.findGeneralSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all general settings
     */
    async listGeneralSettingsRaw(): Promise<runtime.ApiResponse<Array<GeneralSettings>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generalSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneralSettingsFromJSON));
    }

    /**
     * List all general settings
     */
    async listGeneralSettings(): Promise<Array<GeneralSettings>> {
        const response = await this.listGeneralSettingsRaw();
        return await response.value();
    }

    /**
     * Update general settings
     */
    async updateGeneralSettingsRaw(requestParameters: UpdateGeneralSettingsRequest): Promise<runtime.ApiResponse<GeneralSettings>> {
        if (requestParameters.generalSettings === null || requestParameters.generalSettings === undefined) {
            throw new runtime.RequiredError('generalSettings','Required parameter requestParameters.generalSettings was null or undefined when calling updateGeneralSettings.');
        }

        if (requestParameters.generalSettingId === null || requestParameters.generalSettingId === undefined) {
            throw new runtime.RequiredError('generalSettingId','Required parameter requestParameters.generalSettingId was null or undefined when calling updateGeneralSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generalSettings/{generalSettingId}`.replace(`{${"generalSettingId"}}`, encodeURIComponent(String(requestParameters.generalSettingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeneralSettingsToJSON(requestParameters.generalSettings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralSettingsFromJSON(jsonValue));
    }

    /**
     * Update general settings
     */
    async updateGeneralSettings(requestParameters: UpdateGeneralSettingsRequest): Promise<GeneralSettings> {
        const response = await this.updateGeneralSettingsRaw(requestParameters);
        return await response.value();
    }

}
