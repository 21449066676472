import * as React from "react";
import { Box, Paper, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./info-component.styles";
import { ReactComponent as Logo } from "../../../resources/svg/Haltian-logo.svg"
import { DeviceGroup, DeviceSettings } from "../../../generated/client";
import strings from "../../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  device: boolean;
  selectedDeviceGroup?: DeviceGroup;
  selectedDevice?: DeviceSettings;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Info component
 */
class InfoComponent extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render
   */
  public render = () => {
    const { device } = this.props;

    return (
      <Paper>
        <Box p={ 4 }>
        { device ?
          this.renderDeviceInfo() :
          this.renderDeviceGroupInfo()
        }
        </Box>
      </Paper>
    );
  }

  /**
   * Renders device info
   */
  private renderDeviceInfo = () => {
    const { selectedDevice, classes } = this.props;

    if (!selectedDevice) {
      return (
        <Typography variant="h3">
          { strings.devicesView.noSelection }
        </Typography>
      );
    }

    return(
      <>
        <div className={ classes.infoTopWrapper }>
          <Logo className={ classes.logo }/>
          <Box color="#828282">
            <Typography variant="h5" color="inherit">
              { selectedDevice.deviceInfo?.lastUpdate }
            </Typography>
          </Box>
        </div>
        <Box mt={ 4 }>
          <Typography variant="h2">
            { selectedDevice.deviceName }
          </Typography>
        </Box>
        <div className={ classes.infoContainerId }>
          <Box mr={ 1 }>
            <Typography variant="h5" color="inherit">
              { strings.devicesView.id }
            </Typography>
          </Box>
          <Typography variant="h5" color="inherit">
            { selectedDevice.deviceId }
          </Typography>
        </div>
        <div className={ classes.infoContainerBlock }>
          <Typography variant="h4">
            { selectedDevice.description ? selectedDevice.description : strings.devicesView.deviceDescription }
          </Typography>
        </div>
        <div className={ classes.infoContainerBlock }>
          <Box mr={ 1 }>
            <Typography variant="h2" >
              { strings.devicesView.battery }
            </Typography>
          </Box>
          <Typography variant="h4">
            { selectedDevice.deviceInfo?.battery + "%" }
          </Typography>
        </div>
      </>
    );
  }

  /**
   * Renders device group info
   */
  private renderDeviceGroupInfo = () => {
    const { selectedDeviceGroup, classes } = this.props;

    return(
      <>
        <Logo className={ classes.logo }/>
        <Box mt={ 4 } mb={ 2 }>
          <Typography variant="h2">
            { selectedDeviceGroup?.groupName }
          </Typography>
        </Box>
        <Typography variant="h3">
          { selectedDeviceGroup?.description ?? "" }
        </Typography>
      </>
    );
  }
}

export default (withStyles(styles)(InfoComponent));
