import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {
}

/**
 * Settings icon component
 */
const SettingsIcon: React.FC<Props> = props => {
  return (
    <SvgIcon { ...props }>
      <rect width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"/>
      <path d="M12.8 3V3.9L13.6 4.1C14.6 4.3 15.6 4.7 16.4 5.3L17.1 5.8L17.7 5.2L18 4.8L19.2 6L18.9 6.3L18.3 6.9L18.8 7.6C19.4 8.5 19.8 9.4 20 10.4L20.2 11.2H21V12.8H20.9H20.1L19.9 13.6C19.7 14.6 19.3 15.6 18.7 16.4L18.2 17.1L18.8 17.7L19.1 18L18 19.2L17.7 18.9L17.1 18.3L16.4 18.8C15.5 19.4 14.6 19.8 13.6 20L12.8 20.2V21H11.2V20.9V20.1L10.4 19.9C9.4 19.7 8.4 19.3 7.6 18.7L6.9 18.2L6.3 18.8L6 19.2L4.8 18L5.1 17.7L5.7 17.1L5.2 16.4C4.6 15.5 4.2 14.6 4 13.6L3.8 12.8H3.1H3V11.2H3.1H3.9L4.1 10.4C4.3 9.4 4.7 8.4 5.3 7.6L5.8 6.9L5.1 6.3L4.8 6L6 4.8L6.3 5.1L6.9 5.7L7.6 5.2C8.5 4.6 9.4 4.2 10.4 4L11.2 3.8V3.1V3H12.8ZM13.8 2H10.2V3.1C9 3.3 7.9 3.8 7 4.4L6 3.4L3.4 6L4.4 7C3.8 8 3.3 9 3.1 10.2H2V13.8H3.1C3.3 15 3.8 16.1 4.4 17L3.4 18L6 20.6L7 19.6C8 20.2 9 20.7 10.2 20.9V22H13.8V20.9C15 20.7 16 20.2 17 19.6L18 20.6L20.6 18L19.6 17C20.2 16 20.7 15 20.9 13.8H22V10.2H20.9C20.7 9 20.2 7.9 19.6 7L20.6 6L18 3.4L17 4.4C16 3.8 15 3.3 13.8 3.1V2Z" fill="black"/>
      <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" stroke="black" fill="transparent"/>
    </SvgIcon>
  );
}

export default SettingsIcon;
