/* tslint:disable */
/* eslint-disable */
/**
 * Haltian Metatavu API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralSettings
 */
export interface GeneralSettings {
    /**
     * 
     * @type {string}
     * @memberof GeneralSettings
     */
    id?: string;
    /**
     * Global save threshold (in milliseconds) for all devices
     * @type {number}
     * @memberof GeneralSettings
     */
    saveInterval: number;
    /**
     * Global interval for cache data update for message-handler
     * @type {number}
     * @memberof GeneralSettings
     */
    updateInterval: number;
}

export function GeneralSettingsFromJSON(json: any): GeneralSettings {
    return GeneralSettingsFromJSONTyped(json, false);
}

export function GeneralSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'saveInterval': json['saveInterval'],
        'updateInterval': json['updateInterval'],
    };
}

export function GeneralSettingsToJSON(value?: GeneralSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'saveInterval': value.saveInterval,
        'updateInterval': value.updateInterval,
    };
}


