import { Box, FormControl, IconButton, InputLabel, List, MenuItem, Paper, Select, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { MeasurementName, MeasurementRange } from "../../../generated/client";
import strings from "../../../localization/strings";
import { styles } from "./limit-component.styles";
import theme from "../../../theme/theme";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  measurementRanges: MeasurementRange[];
  usedMeasurementNames: MeasurementName[];
  addLimitValues: () => void;
  numberFieldValueChange: (measurementRange: MeasurementRange) => void;
  selectMeasurementNameChange: (measurementRange: MeasurementRange, prevName: string) => void;
  deleteMeasurementRange: (measurementRange: MeasurementRange) => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * List item element
 */
class LimitComponent extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, usedMeasurementNames } = this.props;

    return (
      <>
        <div className={ classes.contentContainer }>
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography variant="h2" style={{ marginRight: theme.spacing(1) }}>
              { strings.deviceGroupsView.limitValues }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
          </Box>
          <IconButton
            color="primary"
            disabled={ (usedMeasurementNames.length >= Object.keys(MeasurementName).length) }
            title={ strings.deviceGroupsView.addNewLimitValue }
            onClick={ this.addLimitValuesClickHandler }
          >
            <AddIcon/>
          </IconButton>
        </div>
        <Paper>
          <div className={ classes.limitValuesListWrapper }>
            { this.renderLimitValuesList() }
          </div>
        </Paper>
      </>
    );
  }

  /**
 * Renders limit values list
 */
  private renderLimitValuesList = () => {
    const { measurementRanges, classes } = this.props;

    if (!measurementRanges.length) {
      return <Typography>{ strings.deviceGroupsView.noLimitsSet }</Typography>;
    }

    return (
      <List className={ classes.limitRow }>
        { measurementRanges.map(this.renderMeasurementRange) }
      </List>
    );
  }

  /**
   * Renders measurement range
   *
   * @param measurementRange measurement range
   */
  private renderMeasurementRange = (measurementRange: MeasurementRange) => {
    const { classes } = this.props;
    const selectItems = this.renderMeasurementRangeSelectItems();

    return(
      <div className={ classes.limitRowItem }>
        <div className={ classes.limitRowItemSelectWrapper }>
          <FormControl 
            variant="filled"
            fullWidth={ true }
          >
            <InputLabel id="input-label">
              { strings.deviceGroupsView.measurementValue }  
            </InputLabel>
            <Select
              id="measurementName"
              labelId="input-label"
              value={ measurementRange.measurementName }
              onChange={ this.onSelectValueChangeHandler(measurementRange) }
            >
              { selectItems }
            </Select>
          </FormControl>
        </div>
        <div className={ classes.limitRowItemValueWrapper }>
          <TextField
            id="minValue"
            label={ strings.deviceGroupsView.minValue }
            variant="filled"
            type="number"
            value={ measurementRange.minValue ?? 0 }
            onChange={ this.onNumberValueChangeHandler(measurementRange) }
          />
        </div>
        <div className={ classes.limitRowItemValueWrapper }>
          <TextField
            id="maxValue"
            label={ strings.deviceGroupsView.maxValue }
            variant="filled"
            type="number"
            value={ measurementRange.maxValue ?? 0 }
            onChange={ this.onNumberValueChangeHandler(measurementRange) }
          />
        </div>
        <div className={ classes.limitRowItemButtonWrapper }>
          <IconButton
            color="secondary"
            onClick={ () => this.onDeleteMeasurementRangeClickHandler(measurementRange) }
          >
            <DeleteOutlineIcon/>
          </IconButton>
        </div>
      </div>
    );
  }

  /**
   * Renders measurement range select items
   */
  private renderMeasurementRangeSelectItems = () => {
    const { usedMeasurementNames } = this.props;

    return Object.values(MeasurementName).map(name =>
      <MenuItem
        key={ name }
        value={ name }
        disabled={ usedMeasurementNames.includes(name) }
      >
        { strings.measurementName[name as keyof object] }
      </MenuItem>
    );
  }

  /**
   * Event handler for on select value change
   * 
   * @param measurementRange measurement range
   */
  private onSelectValueChangeHandler = (measurementRange: MeasurementRange) => async (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    const { selectMeasurementNameChange } = this.props;
    const value = event.target.value as MeasurementName;

    value && await selectMeasurementNameChange(
      {
        measurementName: value,
        minValue: measurementRange.minValue,
        maxValue: measurementRange.maxValue
      },
      measurementRange.measurementName
    );
  }

  /**
   * Event handler for on number field value change
   * 
   * @param measurementRange measurement range
   */
  private onNumberValueChangeHandler = (measurementRange: MeasurementRange) => async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { numberFieldValueChange } = this.props;
    const { id, value } = event.target;
    let numberValue;

    if (value === null) {
      numberValue = 0;
    } else {
      numberValue = Number(value);
    }

    await numberFieldValueChange({ ...measurementRange, [id]: numberValue });

  }

  /**
   * Event handler for add limit values click
   */
  private addLimitValuesClickHandler = async () => {
    const { addLimitValues } = this.props;

    await addLimitValues();
  }

  /**
   * Event handler for delete measurement range click
   * 
   * @param measurementRange measurement range
   */
  private onDeleteMeasurementRangeClickHandler = async (measurementRange: MeasurementRange) => {
    const { deleteMeasurementRange } = this.props;

    await deleteMeasurementRange(measurementRange);
  }
}

export default (withStyles(styles)(LimitComponent));
